<div class="app_container">
  <router-outlet> </router-outlet>

  <section style="background-color: #3d4c6f">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="bg-primary text-white p-5 p-lg-6 rounded-3">
            <h4 class="text-white fs-1 fs-lg-2 mb-1">
              Sign up for email alerts
            </h4>
            <p class="text-white">Stay current with our latest insights</p>
            <form
              action="https://formsubmit.co/info@comedsglobal.com"
              method="POST"
              class="mt-4"
            >
              <div class="row align-items-center">
                <div class="col-md-7 pe-md-0">
                  <div class="input-group">
                    <input
                      required
                      class="form-control"
                      type="email"
                      name="Email"
                      placeholder="Enter Email Here"
                    />
                  </div>
                </div>
                <div class="col-md-5 mt-3 mt-md-0">
                  <div class="d-grid">
                    <button class="btn btn-warning" type="submit">
                      <span class="text-primary fw-semi-bold">Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 mt-4 mt-lg-0">
          <div class="row justify-content-center">
            <!-- <div class="col-6 col-lg-4 text-white ms-lg-auto">
            <ul class="list-unstyled">
              <li class="mb-3">
                <a class="text-white" [routerLink]="['contact_us']"
                  >Contact Us</a
                >
              </li>
              <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
              <li class="mb-3">
                <a class="text-white" href="#!">Privacy Policy</a>
              </li>
              <li class="mb-3">
                <a class="text-white" href="#!">Terms of Use</a>
              </li>
              <li class="mb-3">
                <a class="text-white" href="#!">Global Office</a>
              </li>
              <li class="mb-3">
                <a class="text-white" href="#!">Local Office</a>
              </li>
            </ul>
          </div> -->
            <div class="col-6 col-sm-5 ms-sm-auto">
              <ul class="list-unstyled">
                <li class="mb-3">
                  <a
                    class="text-decoration-none d-flex align-items-center"
                    href="https://www.linkedin.com/company/comeds/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="brand-icon me-3"
                      ><span class="fab fa-linkedin-in"></span
                    ></span>
                    <h5 class="fs-0 text-white mb-0 d-inline-block">
                      Linkedin
                    </h5>
                  </a>
                </li>
                <li class="mb-3">
                  <a
                    class="text-decoration-none d-flex align-items-center"
                    href="https://twitter.com/comedsglobal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="brand-icon me-3"
                      ><span class="fab fa-twitter"></span
                    ></span>
                    <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                  </a>
                </li>
                <li class="mb-3">
                  <a
                    class="text-decoration-none d-flex align-items-center"
                    href="https://www.facebook.com/profile.php?id=100086404268516"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="brand-icon me-3"
                      ><span class="fab fa-facebook-f"></span
                    ></span>
                    <h5 class="fs-0 text-white mb-0 d-inline-block">
                      Facebook
                    </h5>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer
    class="footer bg-primary text-center py-4"
    style="background-color: rgba(255, 255, 255, 0.85)"
  >
    <div class="container">
      <div class="row align-items-center opacity-85 text-white">
        <div class="col-sm-3 text-sm-start">
          <a href="#">
            <img
              src="assets/img/company_logo/comeds.png"
              width="120px"
              height="29px"
              alt="logo"
            />
          </a>
        </div>
        <div class="col-sm-6 mt-3 mt-sm-0">
          <p class="lh-lg mb-0 fw-semi-bold trade_mark" style="color: #2a3855">
            &copy; 2023 Comeds Global
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>
